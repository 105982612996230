@import "../../assets/scss/variables";

.signup-form {
    margin-bottom: 3rem;
    .form-control {
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.5rem 0.75rem;
        border: 0;
        box-shadow: $shadow;
    }
    button {
        width: 100%;
        text-align: center;
        height: calc(1.5em + 1.5rem + 2px);
        padding: 0.5rem 0.75rem;
        color: #FFF;
        border-radius: 0.25rem;
    }
}

