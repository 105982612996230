@import "../variables";

#home {
    background-color: #FFFAF2;
    padding: 15px 0 30px;
    .btnBox {
        width: 100%;
        padding: 0 1rem;
        .btn {
            width: 100%;
            font-size: 17px;
            font-weight: bold;
            padding: 1.125rem 0.75rem;
        }
    }
}