@import "../../assets/scss/variables";

.edit-pic {
    .card {
        border: 0;
        border-radius: 0;
        .custom-file-upload {
            width: 154px;
            height: 154px;
            margin: auto;
            border-radius: 50%;
    display: inline-block;
    position: relative;
    padding: 2px;
    cursor: pointer;
    background: #a2a2a2;
            .img-wrap {
                position: relative;
                width: 150px;
                height: 150px;
                overflow: hidden;
                border-radius: 50%;
                margin: auto;
                background-color: #FFF;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            input {
                display: none;
            }
        }
    }
}
