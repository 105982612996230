@import "../../assets/scss/variables";

/* NAVIGATION */
#navigation {
    position: fixed;
    max-width: 480px;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    background-color: #FFF;
    z-index: 10;
    box-shadow: $shadow;
    nav {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 70px;
            height: 70px;
            background-color: #FFF;
            border-radius: 50%;
            box-shadow: $shadow;
            z-index: -1;
        }
        ul {
            margin: 0;
            padding-left: 0;
            list-style-type: none;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding: 10px 15px;
            background-color: #FFFFFF;
            .nav-item {
                width: 20%;
                .nav-link {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: $grey;
                    font-size: 9px;
                    font-weight: bold;
                    padding: 0;
                    svg {
                        path,
                        circle,
                        ellipse {
                            fill: $grey;
                        }
                    }
                    span {
                        display: block;
                        margin-top: 2px;
                        text-align: center;
                    }
                }
                &:first-child {
                    .nav-link {
                        span {
                            margin-top: 5px;
                        }
                    }
                }
                &:nth-child(3) {
                    .nav-link {
                        span {
                            margin-top: 9px;
                        }
                    }
                }
                &.active {
                    .nav-link {
                        color: $pink;
                        svg {
                            path,
                            circle,
                            ellipse {
                                fill: $pink;
                            }
                        }
                    }
                }
            }
        }
    }
}

.offcanvas-bottom {
    height: auto;
    background-color: transparent;
    border-top: 0;
}

.offcanvas-header {
    .offcanvas-title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $grey;
        svg {
            margin-right: 10px;
            path {
                fill: #555555;
            }
        }
    }
}

.offcanvas-body {
    padding: 0;
    .nav {
        padding: 1rem 0;
        background-color: #FFF;
        max-width: 480px;
        width: 100%;
        margin: auto;
        ul {
            width: 100%;
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
            li {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:nth-child(2) {
                    padding: 0.5rem 1rem;
                }
                .text {
                    flex: 1;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    img {
                        width: 17px;
                        margin-right: 10px;
                    }
                    span {
                        color: #555555;
                    }
                }
            }
        }
    }
    .nav-link,
    .navItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #555555;
        font-size: 15px;
        .text {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
                width: 17px;
                margin-right: 10px;
            }
            span {
                color: #555555;
            }
        }
    }
}