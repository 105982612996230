/* COLOR */
$brown:                       #5C4C2C;
$red:                         #FF5800;
$pink:                        #FF909D;
$pink-dark:                   #c9606f;
$text-link:                   #0079E2;
$grey:                        #A2A2A2;
$white:                       #FFFFFF;
$yellow:                      #FAF230;
$orange:                      #FF9300;
$blue:                        #3167E2;
$purple:                      #813DDE;
$green:                       #15B41B;

/* FONT FAMILY */
$font-DIN:                      din-2014,sans-serif;
$fontHiraginoGothic:            "Hiragino Sans","ヒラギノ角ゴシック","ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
$fontYuGothic:                  "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;

$shadow:                        0px 1px 5px 0px rgba(0,0,0,0.11);

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}