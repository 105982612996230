a {
    color: $text-link;
}

.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
    color: $white;
    background-color: $pink-dark;
    border-color: $pink-dark;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
    color: $white;
    background-color: $pink-dark;
    border-color: $pink-dark;
    box-shadow: $shadow;
}

.btn-check:focus + .btn-light, .btn-light:focus {
    box-shadow: $shadow;
}

.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
    box-shadow: $shadow;
}

.btn-primary {
    color: #fff;
    background-color: $pink;
    border-color: $pink;
}
.btn-primary:hover {
    color: #fff;
    background-color: $pink-dark;
    border-color: $pink-dark;
}

.btn-light {
    color: #212121;
    background-color: #ffffff;
    border-color: #ffffff;
}

.btn-link {
    padding: 0;
    color: $text-link;
}

.form-floating > label {
    font-size: 14px;
    padding: 0.75rem 0.75rem;
}

.form-control:focus,
.btn-check:focus + .btn, .btn:focus,
.form-check-input:focus {
    box-shadow: none;
}