@import "../../assets/scss/variables";

/* TAB SEARCH */
#tabSearch {

    .nav-tabs {
        overflow-x: auto;
        border-bottom: 0;
        .tabs-title {
            flex-wrap: nowrap;
            width: 624px;
            overflow: hidden;
            .nav-item {
                .nav-link {
                    color: #CCCCCC;
                    border-radius: 0;
                    margin-bottom: 0;
                    border: 0;
                    padding: 0 35px;
                    padding-bottom: 8px;
                    font-size: 14px;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg {
                        margin-right: 6px;
                        path {
                            fill: #CCCCCC;
                        }
                    }
                    &.active {
                        background-color: transparent;
                        border-bottom: 3px solid #FF909D;
                        color: $brown;
                        svg {
                            path {
                                fill: $brown;
                            }
                        }
                    }
                }
            }
        }
    }

    .tab-content {
        background: #FFFAF2;
        padding: 36px 24px;
        .desc {
            text-align: center;
            font-size: 13px;
            color: #AC916B;
            margin-bottom: 1.25rem;
        }
        .list-group {
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            .list-group-item {
                width: 28.05%;
                background-color: $pink;
                color: #FFF;
                border: 0;
                border-radius: 0.25rem;
                margin-bottom: 20px;
                padding: 0;
                a {
                    width: 100%;
                    color: #FFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10.5px 3px;
                }
            }
            &.angle {
                justify-content: flex-start;
                .list-group-item {
                    width: auto;
                    margin-right: 12px;
                    background-color: transparent;
                    border-radius: 0;
                    margin-bottom: 12px;
                    a {
                        color: $text-link;
                        width: auto;
                        padding: 0;
                        text-decoration: underline;
                    }
                }
            }
            &.season {
                .list-group-item {
                    width: 14.3%;
                    margin-bottom: 16px;
                    a {
                        padding: 8.5px 3px;
                    }
                }
            }
            &.colors {
                .list-group-item {
                    width: 14.3%;
                    margin-bottom: 16px;
                    @extend .flex-center;
                    a {
                        padding: 8.5px 3px;
                        &.white {
                            background-color: $white;
                            border: 2px solid #707070;
                            color: $brown;
                        }
                        &.yellow {
                            background-color: $yellow;
                            color: $brown;
                            border: 2px solid #5C4C30;
                        }
                        &.orange {
                            background-color: $orange;
                        }
                        &.pink {
                            background-color: #FF76AB;
                            font-size: 12px;
                        }
                        &.red {
                            background-color: #FF4A4A;
                        }
                        &.blue {
                            background-color: $blue;
                        }
                        &.purple {
                            background-color: $purple;
                        }
                        &.green {
                            background-color: $green;
                        }
                        &.brown {
                            background-color: #905923;
                        }
                        &.black {
                            background-color: #000000;
                        }
                    }
                    &.last {
                        width: 31.5%;
                    }
                }
            }
        }
    }
}