@import "../variables";

#upload-image {
    background-color: #FFFAF2;
    .heading {
        text-align: center;
        color: $brown;
        font-size: calc(1.150rem + 0.3vw);
        font-weight: bold;
        margin-bottom: 0;
        padding: 2rem 1rem;
    }
    .upload-box {
        background-color: #FFF;
        padding: 1.25rem 0;
        p {
            margin-bottom: 0.65rem;
            font-size: 14px;
            font-weight: bold;
            color: $brown;
            span {
                font-size: 12px;
                color: #9D9D9C;
            }
        }
        .list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .item {
                flex: 1 0 24.25%;
                width: 100%;
                margin-right: 1%;
                margin-bottom: 1%;
                position: relative;
                &:nth-child(4),
                &:nth-child(8),
                &:nth-child(12),
                &:nth-child(16),
                &:nth-child(20) {
                    margin-right: 0;
                }
                .camera {
                    object-fit: none;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                div {
                    width: 100%;
                    height: 22.7vw;
                    background-color: #E5E5E5;
                    @media screen and (min-width: 481px) {
                        height: 110px;
                    }
                }
                span {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 18px;
                    height: 18px;
                    background-color: #FEF5E6;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 8px;
                    font-weight: bold;
                    color: #838383;
                    font-family: $font-DIN;
                }
            }
        }
    }
}

