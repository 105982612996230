@import "../variables";

#sign-up {
    width: 100%;
    padding: 3rem 1rem;
    .logo {
        margin-bottom: 2.5rem;
    }
    .sns-login {
        margin-bottom: 0;
    }
}

.react-datepicker {
    font-family: $fontHiraginoGothic;
    border: 0;
    box-shadow: $shadow;
    border-radius: 0.5rem;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
    display: none;
}

.react-datepicker__header {
    background-color: #FFF;
    border-bottom: 0;
    .cldr {
        .flex-date {
            margin: 0.75rem;
            display: flex;
            justify-content: center;
            align-items: center;
            .btn-month {
                background-color: transparent;
                border: 0;
                padding: 0;
                height: auto;
                width: auto;
                svg {
                    path {
                        fill: #212121;
                    }
                }
            }
            .select-box {
                @extend .flex-center;
                select {
                    background-color: #FFF;
                    border: 0;
                    &:first-child {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: $pink;
}

.form-check-input:checked {
    background-color: $pink;
    border-color: $pink;
} 

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: $pink;
}