@import "../variables";

/* LOGIN */
#login {
    width: 100%;
    height: 100vh;
    padding: 5rem 1rem 1rem;
    @media screen and (max-width: 350px) {
        padding-top: 3rem;
    }
    .logo {
        margin-bottom: 2.5rem;
    }
    .last {
        position: relative;
        // top: 2rem;
        text-align: center;
        font-size: 12px;
        span {
            margin-right: 5px;
        }
        a {
            font-size: 12px;
        }
        .btn-check:focus + .btn, .btn:focus {
            box-shadow: none;
        }
    }
}