@import "../../assets/scss/variables";

/* HEADER */
header {
    position: fixed;
    box-shadow: $shadow;
    max-width: 480px;
    width: 100%;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    background-color: #FFF;
    z-index: 15;
    top: 0;
    .logo {
        height: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}