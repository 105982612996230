@import "../variables";

#error404 {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $fontYuGothic;
    padding: 0 1rem;
    .icon {
        margin-bottom: 2.5rem;
    }
    h4 {
        color: $brown;
        font-weight: bold;
        font-size: calc(1.4rem + 0.3vw);
        text-align: center;
        margin-bottom: 1rem;
    }
    // .desc {
    //     p {
    //         margin-bottom: 0;
    //         font-size: 13px;
    //         font-weight: 500;
    //     }
    // }
}