@import "../../assets/scss/variables";

.box {
    background-color: #FFF;
    padding: 0.65rem 1rem 1rem;
    margin-bottom: 1.75rem;
    .heading {
        font-size: 1rem;
        color: $brown;
        font-family: $fontYuGothic;
        font-weight: bold;
        padding-bottom: 9px;
        position: relative;
        &:before,
        &:after {
            content: "";
            position: absolute;
            height: 3px;
            border-radius: 3px;
            bottom: 0;
            left: 0;
        }
        &:before {
            width: 100%;
            background-color: #F0F0F0;
        }
        &:after {
            width: 113px;
            background-color: $pink;
        }
    }
    table {
        margin-bottom: 0;
        tr {
            td {
                border-bottom-color: #EEEEEE;
                font-size: 15px;
                &:first-child {
                    padding: 0.5rem 0.5rem;
                    > div {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .position {
                            width: 22px;
                            @extend .flex-center;
                            margin-right: 10px;
                        }
                        span {
                            color: #D0D0D0;
                            .up {
                                -webkit-transform: rotate(-45deg);
                                transform: rotate(-45deg);
                                color: #3785E8;
                            }
                            .down {
                                color: #F74444;
                                -webkit-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
                &:last-child {
                    div {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 60px;
                        background-color: #F0F0F0;
                        border-radius: 3px;
                        margin-left: auto;
                        padding: 4px 7px;
                        font-size: 12px;
                        color: #919191;
                        img {
                            width: 15px;
                            margin-right: 6px;
                        }
                    }
                }
            }
        }
    }
    .btn {
        background-color: #F2BD66;
        width: 168px;
        display: flex;
        justify-content: center;
        align-content: center;
        font-size: 13px;
        color: #FFF;
        border-radius: 3rem;
        margin: auto;
        margin-top: 0.75rem;
        padding: 0.225rem 0.50rem;
        span {
            margin-left: 10px;
        }
    }
}
