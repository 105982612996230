@import "../../assets/scss/variables";

#list-flowers {
    padding: 1.4rem 0;
    .collection {
        font-family: $fontYuGothic;
        font-weight: bold;
        font-size: 18px;
        span {
            font-family: $font-DIN;
        }
    }
    .search-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.35rem;
        .has-search {
            flex: 1;
            div {
                position: relative;
                img {
                    position: absolute;
                    left: 14px;
                    top: 12px;
                }
                .form-control {
                    background-color: #F3F3F3;
                    border-radius: 3rem;
                    border-color: #F3F3F3;
                    padding: 6px 38px;
                    padding-right: 15px;
                }
            }
        }
        .sort-by {
            width: 45px;
            padding-left: 15px;
        }
    }
    .card-group {
        display: block;
        .card {
            display: flex;
            padding: 0.7rem;
            flex-direction: row;
            align-items: flex-start;
            border: 0;
            box-shadow: $shadow;
            margin-bottom: 1.30rem;
            border-radius: 0.375rem;
            font-family: $fontYuGothic;
            img {
                width: 96px;
            }
            .card-body {
                padding: 0.25rem 1.25rem;
                span {
                    font-size: 14px;
                    color: #45646A;
                    font-family: $font-DIN;
                }
                .card-title {
                    font-size: 20px;
                    font-weight: 500;
                }
                .card-text {
                    font-size: 14px;
                    overflow: hidden;
                    max-height: 21px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}