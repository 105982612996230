@import "../../assets/scss/variables";

.notification {
    position: absolute;
    right: 26px;
    top: 40px;
    a {
        padding: 0;
        .badge {
            position: absolute;
            top: -6px;
            right: -4px;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: $red;
            color: white;
            font-size: 12px;
            font-weight: bold;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.offcanvas-end {
    border-left: 0;
}
