@import "../variables";

#policy {
    padding: 2.5rem 0.5rem;
    h3 {
        margin-bottom: 1.5rem;
        color: #212121;
    }
    p {
        font-size: 0.9rem;
        line-height: 1.5;
        color: #555555;
    }
    .boxContent {
        margin-top: 1.5rem;
        .item {
            margin-bottom: 2rem;
            .heading {
                font-size: 1.1rem;
                border-bottom: 2px solid $pink;
                padding-bottom: 0.3rem;
                margin-bottom: 0.75rem;
                color: #212121;
            }
            ul {
                padding-left: 1rem;
                li {
                    list-style-type: decimal;
                    font-size: 0.9rem;
                    color: #555555
                }
            }
        }
    }

}