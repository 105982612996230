@import "../../assets/scss/variables";

/* FOOTER */
footer {
    padding: 17px 0 85px;
    > div {
        text-align: right;
        font-size: 13px;
        font-weight: 500;
        font-family: $fontYuGothic;
        color: #626262;
        display: flex;
        justify-content: flex-end;
        padding: 0 15px 13px;
        border-bottom: 1px solid #E0E0E0;
        .btn-link {
            color: $text-link;
            text-decoration: underline;
            margin-left: 5px;
            font-size: 13px;
        }
        &.last {
            border-bottom: 0;
            padding: 5px 15px 0;
            .btn { 
                color: #91919A;
                text-decoration: none;
                margin-left: 15px;
                padding: 0;
                font-size: 13px;
            }
        }
    }
}