@import "../../assets/scss/variables";

/* SNS LOGIN */
.sns-login {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7rem;
    @media screen and (max-width: 350px) {
        margin-bottom: 5rem;
    }
    button {
        width: 48.5%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: $shadow;
        padding: 0.75rem 1rem;
        img {
            height: 22px;
        }
    }
}