@import "~bootstrap/scss/bootstrap";
@import "variables";
@import "custom";

/* BODY */
body {
    background: #EEE;
    font-family: $fontHiraginoGothic;
    #root {
        max-width: 480px;
        width: 100%;
        margin: auto;
        background-color: #FFF;
        min-height: 100vh;
    }
}

.divider-header {
    padding-top: 84px;
}


