@import "../../assets/scss/variables";

/* NAME MEMBER */
#nameMember {
    padding: 20px 0 26px;
    .box {
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item {
            &.left-side {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 225px;
                .profile-img {
                    width: 59px;
                    margin-right: 13px;
                    img {
                        width: 100%;
                    }
                }
                .name {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    h6 {
                        font-size: 17px;
                        font-weight: normal;
                        margin-bottom: 3px;
                    }
                    span {
                        font-size: 10px;
                    }
                }
            }
            &.right-side {
                color: #919191;
                div {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    &:first-child {
                        margin-bottom: 3px;
                    }
                    img {
                        margin-right: 6px;
                        &.camera {
                            width: 15px;
                        }
                    }
                    span {
                        font-size: 14px;
                        font-family: $font-DIN;
                    }
                }
            }
        }
    }
}