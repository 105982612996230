@import "../../assets/scss/variables";

.login-form {
    margin-bottom: 2.5rem;
    .form-control {
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.5rem 0.75rem;
        border: 0;
        box-shadow: $shadow;
    }
    button {
        width: 100%;
        text-align: center;
        height: calc(1.5em + 1.5rem + 2px);
        padding: 0.5rem 0.75rem;
        color: #FFF;
        border-radius: 0.25rem;
    }
}

.login-error {
    .modal-dialog {
        width: 260px;
        margin: auto;
        .modal-content {
            border-radius: 1rem;
            .modal-body {
                padding: 1.5rem;
                h4 {
                    font-size: 18px;
                    text-align: center;
                    line-height: 1.5;
                    margin-bottom: 1rem;
                }
                p {
                    margin-bottom: 0;
                    font-size: 14px;
                    text-align: center;
                    color: #8e8e8e;
                }
            }
            .modal-footer {
                justify-content: center;
                .btn {
                    text-decoration: none;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
    }
}