@import "../../assets/scss/variables";

.logo {
    @extend .flex-center;
    img {
        height: 45px;
    }
    span {
        font-size: 20px;
        font-weight: 600;
        color: $brown;
        font-family: $font-DIN;
    }
}
